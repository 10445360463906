import { ExamesResolverService } from './service/exames-resolver.service';
import { ExamesComponent } from './exames/exames.component';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToolbarModule } from 'primeng/toolbar';
import { DadosCadastraisResolverService } from 'src/app/m_rh/pages/pre-cadastro/service/dados-cadastrais-resolver.service';
import { DocumentosResolverService } from 'src/app/m_rh/pages/pre-cadastro/service/documentos-resolver.service';
import { MenuPreCadastroResolverService } from 'src/app/m_rh/pages/pre-cadastro/service/menu-pre-cadastro-resolver.service';
import { PreCadastroResolverService } from 'src/app/m_rh/pages/pre-cadastro/service/pre-cadastro-resolver.service';
import { PaginasRhRoutes } from 'src/app/m_rh/paginas-rh.routes';
//import { CurrencyMaskModule } from 'ng2-currency-mask';

import { AutoCompleteModule, CardModule, DropdownModule, InputMaskModule, MessageModule } from 'primeng/primeng';
import { MessageInputModule } from 'src/app/util/components/message-input/message-input.module';
import { FormDebugModule } from 'src/app/_util/form-debug/form-debug.module';
import { DadosCadastraisComponent } from './dados-cadastrais/dados-cadastrais.component';
import { DocumentosComponent } from './documentos/documentos.component';
import { MenuPreCadastroComponent } from './menu-pre-cadastro/menu-pre-cadastro.component';
import { PreCadastroComponent } from './pre-cadastro.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig } from 'ng2-currency-mask/src/currency-mask.config';
import { DependentesComponent } from './dependentes/dependentes.component';
import { DependentesResolverService } from './service/dependentes-resolver.service';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  //allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: '.',
};
@NgModule({
  declarations: [
    PreCadastroComponent,
    DadosCadastraisComponent,
    MenuPreCadastroComponent,
    DocumentosComponent,
    ExamesComponent,
    DependentesComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    /*MY ROUTINGS*/
    PaginasRhRoutes,
    //PRIMENG
    CurrencyMaskModule,
    AutoCompleteModule,
    ButtonModule,
    CardModule,
    ConfirmDialogModule,
    DropdownModule,
    InputTextModule,
    InputMaskModule,
    MessageModule,
    MessageInputModule,
    MessagesModule,
    SelectButtonModule,
    ToolbarModule,
    FormDebugModule,
  ],
  providers: [
    DadosCadastraisResolverService,
    DocumentosResolverService,
    ExamesResolverService,
    DependentesResolverService,
    PreCadastroResolverService,
    MenuPreCadastroResolverService,
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
  ],
})
export class PreCadastroModule {}
