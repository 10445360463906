import { ObjectUtil } from 'src/app/util/service/object-util.service';
import { RetornoResponse } from 'src/app/core/model/retorno-response';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { Subscription } from 'rxjs';
import { Labels } from 'src/app/core/constant/labels';
import { CandidatoVagaHttpService } from 'src/app/core/modulos/rh/http/candidato-vaga-http.service';
import { PropriedadesTabela } from 'src/app/core/modulos/tabela/constant/propriedades-tabela';
import { CidadeHttpService } from 'src/app/core/modulos/tabela/http/cidade-http.service';
import { CandidatoVagaEdit } from 'src/app/m_rh/pages/pre-cadastro/model/candidato-vaga-edit';
import { InputUtilService } from 'src/app/_util/input-util/input-util.service';
import { LoadingService } from 'src/app/_util/loading/loading.service';
import { FormUtil } from 'src/app/util/service/form-util.service';
import { MessageUtilService } from 'src/app/util/service/message-util.service';
import { DependentesResolverService } from '../service/dependentes-resolver.service';
import { DocumentoCandidatoEdit } from '../model/documento-candidato-edit';
import { DependenteCandidatoEdit } from '../model/documento-dependente-edit';

@Component({
  selector: 'app-dependentes',
  templateUrl: './dependentes.component.html',
  styleUrls: ['./dependentes.component.scss'],
})
export class DependentesComponent implements OnInit {
  bean: DependenteCandidatoEdit;
  candidatoSessao: any;
  cfg: any = {};
  itemSelecionado: DependenteCandidatoEdit = new DependenteCandidatoEdit();
  listaDependenteCandidato: DependenteCandidatoEdit[];
  form: FormGroup;
  id: number;
  corrigir: boolean;
  passos: boolean[] = [true, false, false];
  //prettier-ignore
  passosFormRequired: string[][] = [//CAMPOS OBRIGATÓRIO EM CADA PASSO, UTILIZADO PARA VERIFICAR SE O USUÁRIO PODE AVANÇAR PARA O PRÓXIMO PASSO.
   //'dadosDependentes.possuiDependentes'
    [],
    [,'grauParentesco','dataNascimento','cpfCnpj', 'nome'],
    [ 'numero', 'estadoEmissao', 'dataEmissao', 'orgaoEmissor'],
  ];
  subscription: Subscription = new Subscription();

  optionsGrauInstrucao: SelectItem[] = [];
  optionsGrauParentesco: SelectItem[] = [];
  optionsSimNao: SelectItem[] = PropriedadesTabela.SIMNAO;

  constructor(
    public lbs: Labels,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private inputUtil: InputUtilService,
    public msgUtil: MessageUtilService,
    private loadingService: LoadingService,
    private confirmationService: ConfirmationService,
    private candidatoVagaHttpService: CandidatoVagaHttpService
  ) {}

  ngOnInit() {
    this.carregarPagina();
  }

  carregarPagina(): void {
    this.loadingService.off();
    this.subscription.add(
      this.activatedRoute.data.subscribe(
        (data: { dataResolver: any }) => {
          this._listarOptionsGrauInstrucao(data);
          this._listarOptionsGrauParentesco(data);
          this.candidatoSessao = data.dataResolver[DependentesResolverService.CANDIDATOSESSAO];
          this.listaDependenteCandidato = data.dataResolver[DependentesResolverService.DEPENDENTECANDIDATO];
          this.loadingService.onLoading(false);
          this.carregarForm();
        },
        (error) => {
          this.loadingService.off();
        }
      )
    );
  }

  voltarPasso(anterior: number): void {
    this.msgUtil.limpar();
    this.passos = [false, false, false];
    this.passos[anterior] = true;
    sessionStorage.setItem('dependentes', JSON.stringify(this.form.value));
  }

  avancarPasso(proximo: number): void {
    this.msgUtil.limpar();
    const passoAtual = proximo - 1;
    if (this._validarFomularioPasso(passoAtual)) {
      this.passos = [false, false, false];
      this.passos[proximo] = true;
      sessionStorage.setItem('dependentes', JSON.stringify(this.form.value));
    }
  }

  concluir(): void {
    this.confirmationService.confirm({
      message:
        'Ao concluir o preenchimento dos Dependentes, você não poderá mais alterá-los. Tem certeza que os dados foram preenchidos corretamente?',
      accept: () => {
        this.salvarItemSelecionado();
      },
    });
  }

  //FORM

  carregarForm(): void {
    this.form = this.formBuilder.group({
      possuiDependentes: [null, []],
      grauParentesco: [null, [Validators.required]],
      orgaoEmissor: [null, [Validators.required, Validators.maxLength(150)]],
      numero: [null, [Validators.required]],
      estadoEmissao: [null, [Validators.required]],
      dataEmissao: [null, [Validators.required]],
      dataNascimento: [null, [Validators.required]],
      cpfCnpj: [null, [Validators.required]],
      nome: [null, [Validators.required, Validators.maxLength(150)]],
      nomeMae: [null, [Validators.maxLength(150)]],
      grauInstrucao: [null],
    });

    if (sessionStorage.getItem('dependentes')) {
      this.form.setValue(JSON.parse(sessionStorage.getItem('dependentes')));
    }

    Object.keys(this.form.controls).forEach((e) => {
      this._formValueChanges(this.form.get(e));
    });
  }

  validarForm(): boolean {
    if (this.form.invalid) {
      FormUtil.markAllAsDirty(this.form);
      this.msgUtil.warn(this.lbs.m.formInvalido);
      return false;
    }
    return true;
  }

  selecionar(item: DependenteCandidatoEdit): void {
    this.corrigir = true;
    this.id = item.idDependenteCandidato;
    this.bean = item;
    this.carregarForm();
    this._preencherDocumentos(item);
    this.avancarPasso(1);
  }

  private _preencherDocumentos(item: any): void {
    this.form.patchValue({
      grauParentesco: item.grauParentesco,
      cpfCnpj: item.cpfCnpj,
      nome: item.nome,
      dataNascimento: item.dataNascimento,
      nomeMae: item.nomeMae,
      grauInstrucao: item.grauInstrucao,
      dataEmissao: item.dataEmissao,
      estadoEmissao: item.estadoEmissao,
      numero: item.numero,
      orgaoEmissor: item.orgaoEmissor,
    });
    this.itemSelecionado.anexoNome = item.anexo;
    this.itemSelecionado.anexoVersoNome = item.anexoVerso;
  }

  apagarDependente(bean: DependenteCandidatoEdit): void {
    if (bean.dependente.id != null) {
      this.confirmationService.confirm({
        message: `${this.lbs.m.confirmarExcluir} (ID ${bean.idDependenteCandidato})`,
        accept: () => {
          this.loadingService.on();
          this.candidatoVagaHttpService.apagarDependente(bean.idDependenteCandidato).subscribe(
            (data) => {
              this.msgUtil.sucessPop(data.mensagem);
            },
            (error) => {
              this.msgUtil.error(error);
              //this._warning(error, true, false);
              this.loadingService.off();
            },
            () => {
              this.loadingService.off();
              this.listarItens();
            }
          );
        },
      });
    }
  }

  anexoVazio(anexo: any): boolean {
    if (anexo != null) {
      if (anexo.anexo != null && anexo.anexoVerso != null) return false;
    }
    this.msgUtil.error(this.lbs.dependenteCandidato.anexoVazio);
    return true;
  }

  salvarItemSelecionado(): void {
    if (this.validarForm()) {
      if (this.corrigir != true) if (this.anexoVazio(this.itemSelecionado)) return;
      this.loadingService.on();
      this.bean = this.form.value;
      this.bean.anexo = this.itemSelecionado.anexo;
      this.bean.anexoVerso = this.itemSelecionado.anexoVerso;
      if (this.bean.grauParentesco.id) this.bean.grauParentesco = this.bean.grauParentesco.id;
      if (this.bean.grauInstrucao.id) this.bean.grauInstrucao = this.bean.grauInstrucao.id;
      if (this.id != null) this.bean.idDependenteCandidato = this.id;
      this.candidatoVagaHttpService.salvarDependentesCandidato(this.bean, this.idVaga).subscribe(
        (next: RetornoResponse) => {
          this.loadingService.off();
          sessionStorage.removeItem('dependentes');
          this.corrigir = null;
          this.form.reset();
          this.form.patchValue({
            possuiDependentes: true,
          });
          this.voltarPasso(0);
          this.listarItens();
          this.msgUtil.sucessPop(next.mensagem);
        },
        (error) => {
          this.msgUtil.error(error);
          this.loadingService.off();
        },
        () => {
          //this.loadingService.off();
        }
      );
    }
  }
  //OPTIONS

  listarItens(): void {
    this.loadingService.on();
    this.candidatoVagaHttpService.listarDependentesCandidato(this.candidatoSessao.id).subscribe((next) => {
      this.loadingService.off();
      this.listaDependenteCandidato = next;
    });
  }

  private _listarOptionsGrauInstrucao(data: { dataResolver: any }) {
    this.optionsGrauInstrucao = this.inputUtil.toSelectItem(
      data.dataResolver[DependentesResolverService.GRAUINSTRUCAO],
      'label'
    );
  }

  private _listarOptionsGrauParentesco(data: { dataResolver: any }) {
    this.optionsGrauParentesco = this.inputUtil.toSelectItem(
      data.dataResolver[DependentesResolverService.GRAUPARENTESCO],
      'label'
    );
  }
  //OUTROS

  voltarParaMenu(): void {
    this.router.navigate(['../menu'], { relativeTo: this.activatedRoute });
  }

  private _validarFomularioPasso(passo: number): boolean {
    let valido: boolean = true;
    let message = this.lbs.m.formInvalido;
    const fields = this.passosFormRequired[passo];
    fields.forEach((field: string) => {
      if (this.form.get(field).invalid) {
        valido = valido ? false : valido;
        this.form.get(field).markAsTouched();
        this.form.get(field).markAsDirty();
      }

      if (field === 'dataNascimento') {
        if (this.form.get(field).value) {
          //Convertendo valor preenchido no campo para Date
          const arrDataNascimento = this.form.get(field).value.split('/');
          const dataNascimento = new Date(arrDataNascimento[2], arrDataNascimento[1] - 1, arrDataNascimento[0]);
          const dataAtual = new Date();

          let idade = dataAtual.getFullYear() - dataNascimento.getFullYear();
          //Verifica se já fez aniversário no ano atual
          const mes_atual = dataAtual.getMonth() + 1;
          const dia_atual = dataAtual.getDate();
          const mes_aniversario = dataNascimento.getMonth() + 1;
          const dia_aniversario = dataNascimento.getDate();
          // if (mes_atual < mes_aniversario || (mes_atual == mes_aniversario && dia_atual < dia_aniversario)) {
          //   idade = idade < 1 ? 0 : idade--;
          // }

          // if (idade < 14 || idade > 100) {
          //   valido = valido ? false : valido;
          //   this.form.get(field).markAsTouched();
          //   this.form.get(field).markAsDirty();
          //   message = 'Verifique a DATA DE NASCIMENTO, pois candidatos com menos de 14 anos não são permitidos.';
          // }
        }
      }
    });
    if (valido) {
      this.form.markAsUntouched();
      this.form.markAsPristine();
    } else {
      this.msgUtil.warn(message);
    }
    return valido;
  }

  private _formValueChanges(control: any): void {
    if (control instanceof FormGroup) {
      const controls: any = (control as FormGroup).controls;
      Object.keys(controls).forEach((e) => {
        this._formValueChanges(controls[e]);
      });
    } else {
      control.valueChanges.subscribe((value) => {
        const isString = typeof value == 'string';
        if (isString && value != value.toUpperCase()) {
          control.setValue(value.toUpperCase());
        }
      });
    }
  }
  private get idVaga(): number {
    return Number.parseInt(sessionStorage.getItem('idVaga'));
  }

  selecionarFoto(event) {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.onloadend = () => {
      this.resizeImage(file, 800, 800, (name, result?) => {
        this.itemSelecionado.anexo = result || reader.result;
        this.itemSelecionado.anexoNome = name || file.name;
      });
    };
    reader.readAsDataURL(file);
  }
  selecionarFotoVerso(event) {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.onloadend = () => {
      this.resizeImage(file, 800, 800, (name, result?) => {
        this.itemSelecionado.anexoVerso = result || reader.result;
        this.itemSelecionado.anexoVersoNome = name || file.name;
      });
    };
    reader.readAsDataURL(file);
  }
  resizeImage(
    file: File,
    maxWidth: number,
    maxHeight: number,
    callback: (name: string, result?: string | ArrayBuffer) => void
  ) {
    let image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      let width = image.width;
      let height = image.height;
      if (width <= maxWidth && height <= maxHeight) {
        callback(file.name);
      }
      let newWidth;
      let newHeight;
      if (width > height) {
        newHeight = height * (maxWidth / width);
        newWidth = maxWidth;
      } else {
        newWidth = width * (maxHeight / height);
        newHeight = maxHeight;
      }
      let canvas = document.createElement('canvas');
      canvas.width = newWidth;
      canvas.height = newHeight;
      let context = canvas.getContext('2d');
      context.drawImage(image, 0, 0, newWidth, newHeight);
      var finalFile = canvas.toDataURL(file.type);
      let reader2 = new FileReader();
      canvas.toBlob(function (blob) {
        reader2.readAsDataURL(blob);
      }, file.type);
      reader2.onloadend = () => {
        callback(file.name, reader2.result);
      };
    };
  }

  get possuiDependentes() {
    const controle = this.form.get('possuiDependentes');
    return controle ? controle.value : null;
  }
}
